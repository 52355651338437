.loading-spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .loading-spinner {
        border: 6px solid rgba(0, 0, 0, 0.1);
        border-top: 6px solid #8840E6;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: spin 1s linear infinite;
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
  